import React from 'react';
import CountUp from 'react-countup';

import style from './style.module.scss';

const Card = props => {
    return (
        <div className={style.card}>
            <span className={style.card_circle}>
                <img src={props.icon} />
            </span>
            <div className={style.card_details}>
                <span><CountUp end={props.number} duration={1}/></span>
                <p>{props.title}</p>
            </div>
        </div>
    );
};

export default Card;