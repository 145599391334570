import React from 'react';

import style from './style.module.scss';
import medulinSponsor from '../../assets/img/medulin.png';
import munchiesSponsor from '../../assets/img/munchies.png';

const Sponsors = () => {
    return (
        <div className={style.sponsors}>
            <p>Sponsors supporting this event</p>
            <div className={style.sponsors_list}>
                <img src={medulinSponsor} />
                <img src={munchiesSponsor} />
            </div>
        </div>
    );
};

export default Sponsors;