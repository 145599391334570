import React from 'react';
import logo from '../../assets/img/logo.png';

import style from './style.module.scss';

const Header = () => {
    console.log('console.log(window.location.href)', window.location.href);
    return (
        <div className={style.header}>
            <div className={style.container}>
                <img src={logo} className={style.logo} />
                {/* <nav>
                    <ul>
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li>
                            <a href="/twg-fest" className={window.location.href.includes('twg-fest') ? style.active : ''}>TWG Fest</a>
                        </li>
                    </ul>
                </nav> */}
            </div>
        </div>
    );
};

export default Header;