import React from "react";
import { gsap } from 'gsap';
import RegisterButton from '../UI/button';
import Button from '../UI/radioButton';
import applyBackground from '../../assets/img/background.png';
import check from '../../assets/svg/check.svg';
import error from '../../assets/img/error.png';

import style from './style.module.scss';
import axios from "axios";

const tempWorkshops = [
    { id: 1, title: 'Slackline', checked: false, allowed: 50},
    { id: 2, title: 'Juggling', checked: false, allowed: 50},
    { id: 4, title: 'Ropedart', checked: false, allowed: 20},
    { id: 5, title: 'PoiPoi', checked: false, allowed: 20},
    { id: 7, title: 'Yoga', checked: false, allowed: 30},
    { id: 8, title: 'Acroyoga', checked: false, allowed: 30},
    { id: 10, title: 'Speleologija', checked: false, allowed: 30},
    { id: 11, title: 'Penjanje', checked: false, allowed: 25},
    { id: 12, title: 'Mandala', checked: false, allowed: 30},
    { id: 13, title: 'Aerial Silk (Beginner)', checked: false, allowed: 16},
    { id: 14, title: 'Aerial Silk (Advanced)', checked: false, allowed: 8},
    { id: 15, title: 'Recycle Plastic', checked: false, allowed: 20},
    { id: 18, title: 'Dragon saff', checked: false, allowed: 10},
    { id: 19, title: 'Vertical Dance', checked: false, allowed: 40},
];

const Registration = () => {
    const [showWorkshops, setShowWorkshops] = React.useState(false);
    const [selectedWorkshops, setSelectedWorkshops] = React.useState([]);
    const [firstname, setFirstname] = React.useState('');
    const [lastname, setLastname] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [registered, setRegistered] = React.useState(false);

    React.useEffect(() => {
        if (selectedWorkshops.length === 0) {
            axios.get('https://together-wegrow.com/api/v1/twg_fest/workshops').then((response) => {
                const newData = response.data.map(workshop => {
                    const frontWorkshop = tempWorkshops.find(temp => temp.id === workshop.id);
                    if (frontWorkshop) {
                        return {
                            id: workshop.id,
                            title: frontWorkshop.title,
                            checked: frontWorkshop.checked,
                            allowed: frontWorkshop.allowed,
                            attending: workshop.attending
                        }
                    }
                }).filter(i => i);
                setSelectedWorkshops(newData);
            }
            );
        }
    }, [selectedWorkshops]);

    const onWorkshopClick = (value, checked) => {
        const newWorkshopsData = selectedWorkshops.map(workshop => {
            if (workshop.title === value) {
                return {
                    ...workshop,
                    checked: checked
                }
            }
            return workshop
        });
        setSelectedWorkshops(newWorkshopsData);
    }

    const handleRegistration = async () => {
        const workshops = selectedWorkshops.map(workshop => workshop.checked === true && workshop.id).filter(workshop => workshop);
        await axios.post('https://together-wegrow.com/api/v1/twg_fest/user', { firstname: firstname, lastname: lastname, email: email, workshops: workshops }).then(() =>
            setRegistered(true)
        ).catch(() => setRegistered(false))
        gsap.fromTo('.arrow', {opacity: 1 , x: 0 }, { opacity: 1 , x: -400 , duration: 1 })
        gsap.fromTo('.registertitle', {opacity: 1 , x: 0 }, { opacity: 1 , x: -500 , duration: 1 })
        gsap.fromTo('.workshops', {opacity: 1, x: 0, display: 'flex'}, { opacity: 0, x: -200, display: 'none', duration: 0.5});
        gsap.fromTo('.button', { opacity: 1, display: 'block', x: 0 }, { opacity: 0, display: 'none', x: -200, duration: 0.5});
        gsap.fromTo('.thankyou', {opacity: 0, x: 200 }, { opacity: 1, display: 'flex', x: 0 , duration: 0.5, delay: 0.5 });
    };

    const onNextStepClick = async() => {
        setShowWorkshops(!showWorkshops)
        if (showWorkshops) {
            gsap.fromTo('.arrow', {opacity: 0, x: -400 }, { opacity: 1 , x: 0 , duration: 1 });
            gsap.fromTo('.workshops', {opacity: 1, x: 0 }, { opacity: 0, display: 'none', x: 200 , duration: 0.5 });
            gsap.fromTo('.registration', {opacity: 0, x: -200 }, { opacity: 1, display: 'flex', x: 0 , duration: 0.5, delay: 0.5 });
            gsap.fromTo('.button', { opacity: 1, x: 0 }, { opacity: 0, display: 'none', x: 200, duration: 1 });
        } else {
            gsap.fromTo('.arrow', {opacity: 0, x: 400 }, { opacity: 1 , x: 0 , duration: 1 })
            gsap.fromTo('.registration', {opacity: 1, x: 0 }, { opacity: 0, display: 'none',x: -200 , duration: 0.5 });
            gsap.fromTo('.workshops', {opacity: 0, x: 200 }, { opacity: 1, x: 0, display: 'flex', duration: 0.5, delay: 0.5 });
            gsap.fromTo('.button', { opacity: 0, x: 200 }, { opacity: 1, display: 'block', x: 0, duration: 1});
        }
    }

    const renderWorkshops = selectedWorkshops.map(workshop => {
        return <Button
            // className={workshop.allowed > workshop.attending ? '' : style.disabled}
            key={workshop.id}
            text={workshop.title}
            // text={workshop.allowed > workshop.attending ? workshop.title : `${workshop.title}(FILLED)`}
            checked={workshop.checked}
            onChecked={onWorkshopClick}
        />
    });

    return (
        <div id='register' className={style.apply}>
            <div className={style.container}>
                <div className={style.apply_wrapper}>
                    <div className={style.apply_wrapper_details}>
                        <img src={applyBackground} />
                        <div className={style.apply_wrapper_details_box}>
                            <div className={style.apply_wrapper_details_title}>
                                <span style={{ color: '#12c990' } }>together</span>
                                we grow
                            </div>
                            <hr/>
                            <p>We are</p>
                            <p style={{ fontSize: '20px' } }>Inviting you to our third festival!</p>
                            <ul>
                                <li>3 Days</li>
                                <li>5 Highlines</li>
                                <li>13 Workshops</li>
                                <li>Soundsystem</li>
                                <li>T-Shirt</li>
                            </ul>
                            <p style={{ fontSize: '20px', margin: '30px 0 0' } }>Entrance:</p>
                            <ul>
                                <li>1 day <b>20€</b></li>
                                <li>3 days <b>50€</b></li>
                            </ul>
                            <p>*Visitors free entrance</p>
                        </div>
                    </div>
                    <div className={style.apply_wrapper_info}>
                        <h3 className="registertitle">Register</h3>
                        <div className={[style.apply_registration, "registration"].join(' ')}>
                            <p>Firstname</p>
                            <input className={style.apply_wrapper_info_input} type='text' placeholder='Enter your firstname' onChange={e => setFirstname(e.target.value)}/>
                            <p>Lastname</p>
                            <input className={style.apply_wrapper_info_input} type='text' placeholder='Enter your lastname' onChange={e => setLastname(e.target.value)}/>
                            <p>Email address</p>
                            <input className={style.apply_wrapper_info_input} type='text' placeholder='Enter your email' onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className={[style.apply_workshops, 'workshops'].join(' ')}>
                            {renderWorkshops}
                        </div>
                        {/* <div className={[style.apply_thankYou, 'thankyou'].join(' ')}>
                            {registered ? <img src={check} style={{ marginBottom: '2rem' }}/> : <img src={error} style={{ width: '251px' }}/>}
                            <h3>{registered ? 'Registrations closed!' : 'Oooops!'}</h3>
                            {registered
                                ? <p>Registrations for workshops are closed, dont't worry, highline and open climbing routes are still waiting for you!<br/><br/>Feel free to visit our festival and don't forget to enjoy.<br/><br/>See you soon!</p>
                                : <p>Something went wrong. Please try againg later or contact us directly via email.<br/><br/>Contact email: <a href='mailto:unitytwg@gmail.com'>unitytwg@gmail.com</a></p>
                            }
                        </div> */}
                        <div className={[style.apply_thankYou, 'thankyou'].join(' ')}>
                            {registered ? <img src={check} style={{ marginBottom: '2rem' }}/> : <img src={error} style={{ width: '251px' }}/>}
                            <h3>{registered ? 'Thank you!' : 'Oooops!'}</h3>
                            {registered
                                ? <p>You have registered for workshops successfully. We are looking forward meeting you at our festival!<br/><br/>See you soon!</p>
                                : <p>Something went wrong. Please try againg later or contact us directly via email.<br/><br/>Contact email: <a href='mailto:unitytwg@gmail.com'>unitytwg@gmail.com</a></p>
                            }
                        </div>
                        <div className={[style.apply_wrapper_info_next, !showWorkshops ? style.active : '', (firstname && lastname && email) ? '' : style.forbidden].join(' ')}>
                            {firstname && lastname && email &&
                            <>
                                <div className='arrow' onClick={() => onNextStepClick()}>
                                    <span>{showWorkshops ? 'Previous Step' : 'Next Step'}</span>
                                    <div className={[style.arrow, showWorkshops ? style.active : ''].join(' ')}></div>
                                </div>
                                <RegisterButton className={[style.registerButton, selectedWorkshops.some(workshop => workshop.checked === true) ? '' : style.disabled].join(' ')} onClick={() => handleRegistration()} />
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Registration;