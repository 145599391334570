import React from 'react';
import style from './style.module.scss';

const Button = props => {
    return (
        <div className={[style.button, props.className, 'button'].join(' ')} onClick={() => props.onClick()}>
            Register
        </div>
    );
};

export default Button;