import React from 'react';

import Header from '../header';
import Footer from '../footer';

import style from './style.module.scss';

const Layout = props => {
    return (
        <div className={style.layout}>
            <Header />
            {props.children}
            <Footer />
        </div>
    );
};

export default Layout;