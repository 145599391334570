import React from 'react';
import logo from '../../assets/img/logo.png';
import alen from '../../assets/img/misko.png';

import style from './style.module.scss';

const Footer = () => {
    return (
        <div className={style.footer}>
            <img src={alen} className={style.footer_background} />
            <div className={style.container}>
                <img src={logo} className={style.logo} />
                <p>Till we are All Together!</p>
                <article className={style.article} style={{ marginBottom: '16px' }}>
                    <i className={style.article_location}></i>
                    <small>Vinkuranska cesta 2, 52100, Vinkuran</small>
                </article>
                <article className={style.article}>
                    <i className={style.article_email}></i>
                    <a href='mailto:unitytwg@gmail.com'><small>unitytwg@gmail.com</small></a>
                </article>
                <ul>
                    <li>
                        <a href='https://www.facebook.com/UnityTWG' target='_blank'><i className={style.facebook}></i></a>
                    </li>
                    <li>
                        <a href='https://www.instagram.com/unitytwg' target='_blank'><i className={style.instagram}></i></a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;