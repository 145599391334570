import React from 'react';

import style from './style.module.scss';

const About = () => {
    return (
        <div className={style.about}>
            <div className={style.contaner}>
                <h3>ABOUT <span style={{ color: '#12c990' } }>THE FESTIVAL</span></h3>
                <p>
                    Three days of TWG birthday celebration is coming up soon and we haven't even said goodbye to the previous one.<br/><br/>
                    This year we will hold even more workshops during the day and a bit more dancing during the night.
                    Last time we were hanging out for three days without a break (our huge respect for all those who endured
                    relentless enjoyment of all activities) and by doing that celebrated our birthday as a true community.
                    This year we are bigger and stronger, and hope to see all of you guys again, just like any interested newcomers.<br/><br/>
                    No special discipline orientation is required, our main interest is to meet and to spend some quality
                    time together – time filled with happiness, enjoyment, motivation, and of course productivity.
                    We want to show you that there is more to life than how we were raised; we want to show you how special
                    you are as individuals and how many talents you have hidden inside of you.<br/><br/>
                    Each discipline requires your complete presence in the moment, which has been really hard to achieve lately
                    since most of us are trapped in a ruthless reality of someone else's perspective on order, work and discipline.
                    Let's break that, let's give yoga an opportunity to teach us how to be calm, let's give acroyoga chance to tech us how to fully trust,
                    juggling how to keep focus, highline to face our fears, flow arts to be passionate, aerial silk to
                    experience real persistence... let's give us a chance to be a real living human beings again, far away from
                    stress, worries and comfort zone.
                </p>
            </div>
        </div>
    );
};

export default About;